#mt-router-outlet-main {
    width: 100%;
}

.home-section {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 90.5vh;
    overflow: auto;
    background-color: #fff;
}